import dayjs, { OpUnitType, QUnitType } from "dayjs";
import duration, { DurationUnitType } from "dayjs/plugin/duration";
import isToday from "dayjs/plugin/isToday";
import isYesterday from "dayjs/plugin/isYesterday";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(duration);
dayjs.extend(isToday);
dayjs.extend(isYesterday);
dayjs.extend(relativeTime);

/**
 * Human readable time from now, or age as in: "3 hours ago".
 */
export const asTimeAgo = (date?: Date): string => dayjs(date).fromNow();

/**
 * Human readable time span duration in a single unit, as in: "18 minutes"
 */
export const asDuration = (seconds: number = 0, unit: string = "seconds"): string =>
  dayjs.duration(seconds, unit as DurationUnitType).humanize();

/**
 * Human readable time span duration, as in: "2 hours, 30 minutes, 14 seconds"
 */
export const asDurationFull = (seconds: number): string => {
  const duration = dayjs.duration(seconds, "seconds");
  const units = [
    { value: duration.years(), singular: "year", plural: "years" },
    { value: duration.months(), singular: "month", plural: "months" },
    { value: duration.days(), singular: "day", plural: "days" },
    { value: duration.hours(), singular: "hour", plural: "hours" },
    { value: duration.minutes(), singular: "minute", plural: "minutes" },
    { value: duration.seconds(), singular: "second", plural: "seconds" },
  ];

  return units
    .filter((unit) => unit.value > 0)
    .map((unit) => `${unit.value} ${unit.value === 1 ? unit.singular : unit.plural}`)
    .join(", ");
};

/**
 * Abbreviated human readable time span duration, as in: "18m, 55s"
 */
export const asDurationAbbreviated = (seconds: number): string => {
  const duration = dayjs.duration(seconds, "seconds");
  const units = [
    { value: duration.years(), suffix: "y" },
    { value: duration.months(), suffix: "m" },
    { value: duration.days(), suffix: "d" },
    { value: duration.hours(), suffix: "h" },
    { value: duration.minutes(), suffix: "m" },
    { value: duration.seconds(), suffix: "s" },
  ];

  return units
    .filter((unit) => unit.value > 0)
    .map((unit) => `${unit.value}${unit.suffix}`)
    .join(", ");
};

/**
 * Timespan since the specified date.  Determine how long it's been since the specified date.
 * Specify dayjs unit of time, such as `month`, `day`, `second`.
 * Specify whole units, or fractional units of time.
 */
export const timeFromNow = (date?: Date, unit: QUnitType | OpUnitType = "minute", float: boolean = false) => {
  const tA = dayjs(date);
  const tB = dayjs(new Date());

  if (tA.isValid() && tB.isValid()) {
    return tB.diff(tA, unit, float);
  }
  return Number.NEGATIVE_INFINITY;
};

/**
 * Whether date is from today
 */
export const dateIsToday = (date?: Date): boolean => dayjs(date).isToday();

/**
 * Whether date is from yesterday.
 */
export const dateIsYesterday = (date?: Date): boolean => dayjs(date).isYesterday();

/**
 * Whether date is before yesterday.
 */
export const dateIsBeforeYesterday = (date?: Date): boolean => {
  const yesterday = dayjs().add(-1, "day");
  return dayjs(date).isBefore(yesterday);
};

/**
 * Date offset as string for API calls
 * @param offset Number of minutes, negative for minutes ago
 */
export const fromMinutes = (offset: number): Date => {
  const date = new Date();
  date.setUTCMinutes(date.getUTCMinutes() + offset);
  return date;
};

/**
 * Date offset as string for API calls
 * @param offset Number of hours, negative for hours ago
 */
export const fromHours = (offset: number): Date => {
  const date = new Date();
  date.setUTCHours(date.getUTCHours() + offset);
  return date;
};

export const fromMonth = (offset: number): Date => {
  const date = new Date();
  date.setUTCMonth(date.getUTCMonth() + offset);
  return date;
};

export const now = () => new Date().toISOString();
export const hourAgo = () => fromHours(-1);
export const dayAgo = () => fromHours(-24);
export const weekAgo = () => fromHours(-24 * 7);
export const monthAgo = () => fromMonth(-1);

export const asIsoString = (date?: Date) => (date ? date.toISOString() : "");
